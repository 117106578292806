import React, { useState, useMemo } from 'react';
import StarryBackground from '../StarryBackground/StarryBackground';
import CatSticker from '../CatSticker/CatSticker';
import './Donations.css';

const Donations = () => {
  const [amount, setAmount] = useState(20);
  const [isLoading, setIsLoading] = useState(false);

  // Generate random stickers with absolute positioning
  const catStickers = useMemo(() => {
    return Array.from({ length: 5 }, (_, index) => ({
      id: index,
      style: {
        position: 'absolute',
        top: `${Math.random() * 90}%`,
        left: `${Math.random() * 90}%`,
        transform: `rotate(${Math.random() * 360}deg) scale(${0.8 + Math.random() * 0.4})`,
      }
    }));
  }, []);

  const API_BASE_URL = process.env.NODE_ENV === 'production' 
  ? 'https://prodadvice.com'
  : 'http://localhost:8000';

const handleDonationSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/create-payment-link`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: amount  // Add the amount from state
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Payment creation failed');
      }

      const data = await response.json();
      if (data.payment_url) {
        window.location.href = data.payment_url;
      } else {
        throw new Error('No payment URL received');
      }
    } catch (error) {
      console.error('Payment error:', error);
      alert(`Произошла ошибка: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
};

  return (
    <div className="donations-container">
      <StarryBackground />
      {catStickers.map(sticker => (
        <CatSticker key={sticker.id} style={sticker.style} />
      ))}

      <div className="donations-content">
        <header className="donations-header">
          <h1>Поддержать проект</h1>
        </header>

        <div className="donations-message">
          <p>Вы можете задонатить на развитие проекта любым удобным вам способом!</p>
        </div>

        <div className="donations-form">
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(Math.max(1, Number(e.target.value)))}
            min="1"
            className="donation-amount"
          />
          <button 
            className="payment-button"
            onClick={handleDonationSubmit}
            disabled={isLoading}
          >
            {isLoading ? 'Загрузка...' : 'Поддержать'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Donations; 